<template>
  <div />
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Main',
    computed: {
      ...mapGetters('settings', ['getMainRouteName'])
    },
    async created() {
      await this.$router.push({ name: this.getMainRouteName })
    }
  }
</script>
